<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<template>
  <div class="display-inline-block margin-right-25">
    <CButton color="dark" variant="outline" :disabled="disabled" @click.prevent.default="showModal">
      <div class="icon-wrapper d-flex align-items-center">
        <CIcon name="cil-arrow-thick-from-top" />
        <span class="ml-1">{{ $t('common.import') }}</span>
      </div>

    </CButton>

    <CModal :title="$t('channel.signTitle')" :show.sync="isShow" :closeOnBackdrop='false'>
      <b-form-file v-model="form.file" :placeholder="$t('channel.placeholderSignTrans')" lang="ru" class="mb-3" required
        :browse-text="$t('common.browse')"></b-form-file>

      <div slot="footer" class="footer-buttons">
        <CButton name="cancelButton" type="button" class="btn btn-secondary" @click="closeModal"> {{ $t('common.cancel')
        }} </CButton>
        <CButton name="submitButton" type="submit" class="btn btn-primary" @click="readFile"> {{ $t('common.import') }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>


<script>
import { BFormFile } from 'bootstrap-vue'

import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import utilMessages from '@/utils/messages'
import { validationMessages } from '@/views/hauberk/messages'


export default {
  name: 'ImportTransactionModal',
  components: {
    BFormFile
  },

  props: {
    disabled: Boolean
  },

  data() {
    return {
      validationMessages: validationMessages,
      isShow: false,
      form: {
        file: null
      },
    }
  },

  validations: {
    form: {
      file: {
        required,
      }
    }
  },

  async mounted() {
    this.initEmpty()

  },

  watch: {},

  computed: {
    isValid() {
      let file;
      if (this.$v.file.$dirty)
        file = true

      return {
        file,
      }
    },

  },

  methods: {
    showModal() {
      this.isShow = true;
    },
    initEmpty() { },
    async readFile() {
      const { file } = this.form;
      let data = new FormData();
      data.append('files', new Blob([file], { type: 'multipart/form-data' }));
      await this.importTransaction(data)
    },
    async importTransaction(data) {
      try {
        await this.$store.dispatch('importTransaction', data);
        this.$toast.success('Пакет импортирован');
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
      this.closeModal();
    },
    closeModal() {
      this.initEmpty()
      this.isShow = false
      this.$v.$reset()
    },
  },

};
</script>


<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.display-inline-block {
  display: inline-block;
}

.margin-right-25 {
  margin-right: 25px;
}
</style>
