<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<template>
  <div
    class='display-inline'
    v-bind:class="{'float-left': updateForm, 'float-right': !updateForm}"
  >
    <CButton
      color='gray'
      class='p-0 m-0'
      @click.prevent='showModal'
      v-if='updateForm'
    >
      <CIcon name='cil-pencil' />
    </CButton>

    <CLink
      v-if='!updateForm'
      @click.prevent.default='showModal'
    >{{$t('channel.addChange')}}
    </CLink>

    <CModal
      :title="title"
      :show.sync='isShow'
      :closeOnBackdrop='false'
      size='xl'
    >
      <CTextarea
        :label="$t('channel.newData')"
        v-model='newConfig'
        :invalidFeedback='validationMessages.required'
        :tooltipFeedback=false
        :isValid='isValid.newChange'
        @change='$v.newData.newChange.$touch()'
        rows='8'
      />

      <TransactionDiff 
        :oldVal="newData.old" 
        :newVal="newConfig" 
        :isClear="true">
      </TransactionDiff>

      <div slot='footer' class='footer-buttons'>
        <CButton
          type='button'
          class='btn btn-secondary'
          @click='closeModal'
        > {{ $t('common.cancel') }}
        </CButton>
        <CButton
          type='button'
          class='btn btn-primary'
          @click='saveChange'
          :disabled='!canSave'
        > {{ $t('common.save') }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>


<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { debounce } from 'lodash';

import { validationMessages } from '@/views/hauberk/messages';

import TransactionDiff from './TransactionDiff.vue';

export default {
  name: 'NewUpdateChangeModal',
  components: {
    TransactionDiff
  },
  props: {
    updateForm: {
      type: Boolean,
      default: false
    },
    step: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      validationMessages: validationMessages,
      isShow: false,
      changeForm: {},
      testData: null,

      newData: {
        jsonpath: '',
        old: '',
        newChange: '',
        type: ''
      }
    };
  },
  validations: {
    newData: {
      jsonpath: {
        required
      },
      old: {},
      newChange: {},
      type: {
        required
      }
    }
  },
  async mounted() {
    this.initEmpty();
  },
  computed: {
    ...mapGetters([]),
    isValid() {
      let jsonpath, newChange, type;
      if (this.$v.newData.jsonpath.$dirty)
        jsonpath = !this.$v.newData.jsonpath.$invalid;

      if (this.$v.newData.newChange.$dirty)
        newChange = !this.$v.newData.newChange.$invalid;

      if (this.$v.newData.type.$dirty)
        type = !this.$v.newData.type.$invalid;

      return {
        jsonpath,
        newChange,
        type
      };
    },
    canSave() {
      return !this.$v.newData.$invalid;
    },
    title() {
      return  `${this.$i18n.t('channel.titleChanges')} (${this.newData.jsonpath})`;
    },
    newConfig: {
      get () {
        return this.newData.newChange;
      },
      set: debounce(function(value) {
        this.newData.newChange = value;
      }, 600)
    }
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    initEmpty() {
      this.newData['jsonpath'] = '';
      this.newData['old'] = '';
      this.newData['newChange'] = '';
      this.newData['type'] = '';

      if (this.step) {
        this.newData.jsonpath = this.step.json_path;
        this.newData.old = atob(this.step.old_json || '');
        this.newData.newChange = atob(this.step.json || '');
        this.newData.type = this.step.type || '';
      }
    },
    async saveChange() {
      this.$emit('save', this.newData);
      this.closeModal();
    },
    closeModal() {
      this.initEmpty();
      this.isShow = false;
      this.$v.$reset();
    }
  }
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.display-inline {
  display: inline;
}
</style>
